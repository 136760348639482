<template>
  <div>
    <filter-fields :filter-options.sync="filtersField" :column-count="4">
      <template #provinsi>
        <v-select
          v-model="selectedProvinsi"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="provinsiOptions"
          class="w-100"
          label="nama"
          :reduce="val => val.kode"
          placeholder="Pilih Provinsi"
        />
      </template>
      <template #kota>
        <v-select
          v-model="selectedKota"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="kotaOptions"
          class="w-100"
          label="nama"
          :reduce="val => val.kode"
          placeholder="Pilih Kota"
        />
      </template>
    </filter-fields>
    <b-card>
      <own-table
        ref="orderTable"
        name-table="orders"
        :table-columns="columns"
        :disable-create-btn="true"
        :use-custom-filter="true"
        :custom-query-filter="queryFilter"
        sort-by="created_at"
        sort-direction="desc"
        @searchFieldOnChange="searchOnChange"
      >
        <template #cell(transaction)="data">
          <div v-if="data.data.item.transaction">
            <b-avatar
              :id="`invoice-row-${
                data.data.item.transaction
                  ? data.data.item.transaction.no_invoice
                  : ''
              }`"
              size="32"
              :variant="`light-${
                resolveInvoiceStatusVariantAndIcon(
                  data.data.item.transaction.status
                ).variant
              }`"
            >
              <feather-icon
                :icon="
                  resolveInvoiceStatusVariantAndIcon(
                    data.data.item.transaction.status
                  ).icon
                "
              />
            </b-avatar>
            <b-tooltip
              :target="`invoice-row-${data.data.item.transaction.no_invoice}`"
            >
              <p class="mb-0">
                {{ data.data.item.transaction.status }}
              </p>
            </b-tooltip>
          </div>
          <div v-else>
            <span>-</span>
          </div>
        </template>
        <template #cell(transaction_date)="data">
          {{
            data.data.item.transaction
              ? formatDate(data.data.item.transaction.created_at)
              : '-'
          }}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="statusOrderVariant[data.data.item.status]">
            <span>{{ statusOrder[data.data.item.status] }}</span>
          </b-badge>
        </template>
        <template #cell(is_refund)="data">
          <b-badge :variant="data.data.item.is_refund ? 'success' : 'danger'">
            <span>{{ data.data.item.is_refund ? 'Ya' : 'Tidak' }}</span>
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <feather-icon
            :id="`branch-row-${data.data.item.no_invoice}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="
              () =>
                $router.push({
                  name: 'order-detail',
                  params: { id: data.data.item.id },
                })
            "
          />
        </template>
      </own-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BFormInput,
  BBadge,
  BDropdown,
  BDropdownItem,
  BCardTitle,
  BTooltip,
  BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  ref,
  onUnmounted,
  watch,
  watchEffect,
} from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import vSelect from 'vue-select'
import { formatDate, title } from '@core/utils/filter'
import { statusOrder, statusOrderVariant } from '@core/utils/constant'
import FilterFields from '@/views/components/filter/Filter.vue'
import useOrder from './useOrder'
// import FilterFields from '../components/filter/Filter.vue'
// import calonAgenStoreModule from './calonAgenStoreModule'

export default {
  components: {
    BCard,
    // FilterFields,
    OwnTable,
    BFormInput,
    vSelect,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCardTitle,
    BTooltip,
    BAvatar,
    FilterFields,
  },
  setup() {
    // const CALON_AGEN_STORE_MODULE = 'calon-agen'
    // if (!store.hasModule(CALON_AGEN_STORE_MODULE)) {
    // 	store.registerModule(CALON_AGEN_STORE_MODULE, calonAgenStoreModule)
    // }
    // onUnmounted(() => {
    // 	store.unregisterModule(CALON_AGEN_STORE_MODULE)
    // })
    // const caloAgenTable = ref(null)
    // const regionAPiUrl = ref('https://permohonancustomer.ingco.co.id/public/api')

    const { resolveInvoiceStatusVariantAndIcon } = useOrder()
    const filtersField = ref([
      {
        key: 'status_pembayaran',
        type: 'select',
        model: null,
        options: [
          {
            label: 'Sudah Bayar',
            value: 'PAID',
          },
          {
            label: 'Belum Bayar',
            value: 'UNPAID',
          },
          {
            label: 'Expired',
            value: 'EXPIRED',
          },
        ],
      },
      {
        key: 'status_order',
        type: 'select',
        model: null,
        options: Object.keys(statusOrder).map(el => {
          const data = {
            label: statusOrder[el],
            value: el,
          }
          return data
        }),
      },
      {
        key: 'is_refund',
        type: 'select',
        model: null,
        options: [
          {
            label: 'Ya',
            value: true,
          },
          {
            label: 'Tidak',
            value: false,
          },
        ],
      },
    ])
    const columns = ref([
      {
        label: 'No. Transaksi',
        key: 'transaction.no_invoice',
      },
      {
        label: 'Tgl. Transaksi',
        key: 'transaction_date',
        sortable: true,
        formatter: val => formatDate(val),
      },
      {
        label: 'Metode Bayar',
        key: 'transaction.type',
        sortable: true,
        formatter: val => title(val, '_'),
      },
      {
        label: 'Status Pembayaran',
        key: 'transaction',
        sortable: true,
      },
      {
        label: 'Refund',
        key: 'is_refund',
        sortable: true,
      },
      {
        label: 'Status Order',
        key: 'status',
        sortable: true,
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const queryFilter = ref('')
    const provinsiOptions = ref([])
    const selectedProvinsi = ref(null)
    const kotaOptions = ref([])
    const selectedKota = ref(null)
    const search = ref('')

    watch(
      [...filtersField.value.map(field => () => field.model)],
      (newValue, prevValue) => {
        queryFilter.value = filtersField.value
          .map(x => (x.model ? `&${x.key}=${x.model}` : ''))
          .join('')
          .concat(`${search.value !== '' ? `&q=${search.value}` : ''}`)
      }
    )
    const searchOnChange = val => {
      console.log(val)
      search.value = val
      queryFilter.value = filtersField.value
        .map(x => (x.model ? `&${x.key}=${x.model}` : ''))
        .join('')
        .concat(`${val !== '' ? `&q=${val}` : ''}`)
    }

    return {
      filtersField,
      columns,
      queryFilter,
      provinsiOptions,
      selectedProvinsi,
      kotaOptions,
      selectedKota,
      searchOnChange,
      resolveInvoiceStatusVariantAndIcon,
      formatDate,
      statusOrder,
      statusOrderVariant,
    }
  },
}
</script>
