<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          v-for="filter in filterOptions"
          :key="filter.key"
          cols="12"
          :md="12 / columnCount"
          class="mb-md-0 mb-2"
        >
          <label>{{ title(filter.key, '_') }}</label>
          <slot v-if="filter.type === 'select'" :name="filter.key">
            <v-select
              v-model="filter.model"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filter.options"
              class="w-100"
              :reduce="val => val.value"
            />
          </slot>
          <slot v-else-if="filter.type !== 'select'" :name="filter.key">
            <b-form-input
              v-model="filter.model"
              :type="filter.type ? filter.type : 'text'"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </slot>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { title } from '@core/utils/filter'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormInput,
    BButton,
  },
  props: {
    filterOptions: {
      type: Array,
      default: () => [],
    },
    columnCount: {
      type: Number,
      default: 12,
    },
  },
  setup(props, context) {
    return {
      title,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
