var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-fields',{attrs:{"filter-options":_vm.filtersField,"column-count":4},on:{"update:filterOptions":function($event){_vm.filtersField=$event},"update:filter-options":function($event){_vm.filtersField=$event}},scopedSlots:_vm._u([{key:"provinsi",fn:function(){return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.provinsiOptions,"label":"nama","reduce":function (val) { return val.kode; },"placeholder":"Pilih Provinsi"},model:{value:(_vm.selectedProvinsi),callback:function ($$v) {_vm.selectedProvinsi=$$v},expression:"selectedProvinsi"}})]},proxy:true},{key:"kota",fn:function(){return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.kotaOptions,"label":"nama","reduce":function (val) { return val.kode; },"placeholder":"Pilih Kota"},model:{value:(_vm.selectedKota),callback:function ($$v) {_vm.selectedKota=$$v},expression:"selectedKota"}})]},proxy:true}])}),_c('b-card',[_c('own-table',{ref:"orderTable",attrs:{"name-table":"orders","table-columns":_vm.columns,"disable-create-btn":true,"use-custom-filter":true,"custom-query-filter":_vm.queryFilter,"sort-by":"created_at","sort-direction":"desc"},on:{"searchFieldOnChange":_vm.searchOnChange},scopedSlots:_vm._u([{key:"cell(transaction)",fn:function(data){return [(data.data.item.transaction)?_c('div',[_c('b-avatar',{attrs:{"id":("invoice-row-" + (data.data.item.transaction
                ? data.data.item.transaction.no_invoice
                : '')),"size":"32","variant":("light-" + (_vm.resolveInvoiceStatusVariantAndIcon(
                data.data.item.transaction.status
              ).variant))}},[_c('feather-icon',{attrs:{"icon":_vm.resolveInvoiceStatusVariantAndIcon(
                  data.data.item.transaction.status
                ).icon}})],1),_c('b-tooltip',{attrs:{"target":("invoice-row-" + (data.data.item.transaction.no_invoice))}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(data.data.item.transaction.status)+" ")])])],1):_c('div',[_c('span',[_vm._v("-")])])]}},{key:"cell(transaction_date)",fn:function(data){return [_vm._v(" "+_vm._s(data.data.item.transaction ? _vm.formatDate(data.data.item.transaction.created_at) : '-')+" ")]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":_vm.statusOrderVariant[data.data.item.status]}},[_c('span',[_vm._v(_vm._s(_vm.statusOrder[data.data.item.status]))])])]}},{key:"cell(is_refund)",fn:function(data){return [_c('b-badge',{attrs:{"variant":data.data.item.is_refund ? 'success' : 'danger'}},[_c('span',[_vm._v(_vm._s(data.data.item.is_refund ? 'Ya' : 'Tidak'))])])]}},{key:"cell(actions)",fn:function(data){return [_c('feather-icon',{staticClass:"mx-1",attrs:{"id":("branch-row-" + (data.data.item.no_invoice) + "-preview-icon"),"icon":"EyeIcon","size":"16"},on:{"click":function () { return _vm.$router.push({
                name: 'order-detail',
                params: { id: data.data.item.id },
              }); }}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }